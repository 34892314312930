import React from "react";
import Layout from "gatsby-plugin-based-on-staticfuse/src/components/Layout";
import SEO from "gatsby-plugin-based-on-staticfuse/src/components/SEO";
import { ReactTypeformEmbed } from "react-typeform-embed";
import CookieConsent from "react-cookie-consent";
import { Text } from "@chakra-ui/core";

export default () => (
  <Layout flexBody={true}>
    <SEO title="Upload" />
      <Text style={{ textAlign: "center" }}>
        The submission form is closed. Thank you for your contributions!
      </Text>

    <CookieConsent
      location="bottom"
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      expires={150}
    >
      <Text style={{ textAlign: "center" }}>
        This website uses cookies to enhance the user experience.
      </Text>
    </CookieConsent>
  </Layout>
);
